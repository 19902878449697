<!--推广设置-->
<template>
  <div style="padding: 20px;">
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <div>
        <span style="font-size: 24px;font-weight: 500;color: #707070;">推广设置</span>
      </div>
      <div>

      </div>
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <div style="text-align: center;">
        <span style="font-size: 30px;font-weight: 500;color: #000000;">推广设置</span>
      </div>
      <el-form ref="form" :model="form" label-width="150px">
        <el-row :gutter="15" style="margin-top: 20px;">
          <el-col :span="15" style="display: flex;">
            <el-form-item label="推广注册佣金：">
              <el-radio v-model="form.fixed_setting.popularize_register.switch" :label="true">开启</el-radio>
              <el-radio v-model="form.fixed_setting.popularize_register.switch" :label="false">关闭</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="固定金额">
              <el-input placeholder="请输入" v-model="form.fixed_setting.popularize_register.value" style="width: 280px!important;">
                <template slot="append">.元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 20px;">
          <el-col :span="15" style="display: flex;">
            <el-form-item label="充值会员佣金：">
              <el-radio v-model="form.fixed_setting.recharge_member.switch" :label="true">开启</el-radio>
              <el-radio v-model="form.fixed_setting.recharge_member.switch" :label="false">关闭</el-radio>
            </el-form-item>
            <el-form-item label="佣金：">
              <el-radio v-model="form.fixed_setting.recharge_member.mode" label="immobilization">固定金额</el-radio>
              <el-radio v-model="form.fixed_setting.recharge_member.mode" label="rate">金额比例</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="固定金额">
              <el-input placeholder="请输入" v-model="form.fixed_setting.recharge_member.value" style="width: 280px!important;">
                <template slot="append">.元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="margin-top: 20px;">
          <el-col :span="15" style="display: flex;">
            <el-form-item label="订单推广收益：">
              <el-radio v-model="form.fixed_setting.lower_order.switch" :label="true">开启</el-radio>
              <el-radio v-model="form.fixed_setting.lower_order.switch" :label="false">关闭</el-radio>
            </el-form-item>
            <el-form-item label="佣金：">
              <!--<el-radio v-model="form.fixed_setting.lower_order.mode" label="immobilization">固定金额</el-radio>-->
              <!--<el-radio v-model="form.fixed_setting.lower_order.mode" label="rate"></el-radio>-->
              <el-radio v-model="form.fixed_setting.lower_order.mode" label="immobilization">基础金额</el-radio>
              <el-radio v-model="form.fixed_setting.lower_order.mode" label="immobilization1">订单总金额</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="金额比例">
              <el-input placeholder="请输入" v-model="form.fixed_setting.lower_order.value" style="width: 280px!important;">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>


        <div>
          <div>司机端活跃度激励规则</div>
          <div class='tuiguangdiv'>
            <div style="margin-bottom: 10px;">
              <el-switch v-model="form.variable_setting.driver.switch"></el-switch>
              <span class='tuiguangdiv2'>司机端选择:</span>
              <el-checkbox v-model="form.variable_setting.driver.driver_select.self" label="共享司机"></el-checkbox>
              <el-checkbox v-model="form.variable_setting.driver.driver_select.share" label="自营司机"></el-checkbox>
            </div>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="每日上线总时长（h）">
                  <el-input placeholder="请输入" v-model="form.variable_setting.driver.online_total_time"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="上线时间段">
                  <el-time-picker
                      format="HH:mm:ss"
                      value-format="HH:mm:ss"
                      is-range
                      :clearable="false"
                      v-model="form.variable_setting.driver.time_horizon.am"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择早餐时间范围">
                    </el-time-picker>

                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="连续在线天数（d）">
                  <el-input placeholder="请输入" v-model="form.variable_setting.driver.continuous_online"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-time-picker
                    value-format="HH:mm:ss"
                    is-range
                    :clearable="false"
                    v-model="form.variable_setting.driver.time_horizon.noon"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围">
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="一次奖励金额随机范围" label-width="160px">
                  <el-input style="display: inline-block; width: 40%; margin-right: 10px;" placeholder="请输入" v-model="form.variable_setting.driver.reward_range.max"></el-input>
                  <el-input style="display: inline-block; width: 40%;" placeholder="请输入" v-model="form.variable_setting.driver.reward_range.min"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="">
                  <el-time-picker
                    value-format="HH:mm:ss"
                    is-range
                    :clearable="false"
                    v-model="form.variable_setting.driver.time_horizon.pm"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围">
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <div class='tuiguangdiv'>
            <div class='tuiguangdiv1'>
              <div>
                <el-switch v-model="form.variable_setting.everyday_join.switch"></el-switch>
                <span class='tuiguangdiv2'>每日接单奖励:</span>
              </div>
              <div>
                <el-button type="primary" @click="Add()">添加</el-button>
              </div>
            </div>
            <el-row :gutter="15" v-for="(item,index) in form.variable_setting.everyday_join.data" :key="index">
              <el-col :span="6">
                <el-form-item label-width="80px" label="不少于：">
                  <el-input placeholder="请输入" v-model="item.min">
                    <template slot="append">单</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label-width="80px" label="奖励：">
                  <el-input placeholder="请输入" v-model="item.award">
                    <template slot="append">元/单</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-button type="danger" @click="del(item,index)">删除</el-button>
            </el-row>
          </div>

          <div class='tuiguangdiv'>
            <div class='tuiguangdiv1'>
              <div>
                <el-switch v-model="form.variable_setting.everyday_join.switch"></el-switch>
                <span class='tuiguangdiv2'>NPS奖励:</span>
              </div>
              <!-- <div>
                <el-button type="primary" @click="Add()">添加</el-button>
              </div> -->
            </div>
            <el-row :gutter="15" v-for="(item,index) in form.variable_setting.everyday_join.data" :key="index">
              <el-col :span="6">
                <el-form-item label-width="80px" label="不少于：">
                  <el-input placeholder="请输入" v-model="item.min">
                    <template slot="append">单</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label-width="80px" label="奖励：">
                  <el-input placeholder="请输入" v-model="item.award">
                    <template slot="append">元/单</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-button type="danger" @click="del(item,index)">删除</el-button>
            </el-row>
          </div>

          <div class='tuiguangdiv'>
            <div class='tuiguangdiv1'>
              <div>
                <el-switch v-model="form.variable_setting.member_join.switch"></el-switch>
                <span class='tuiguangdiv2'>会员接单奖励:</span>
              </div>
              <div>
                <el-button type="primary" @click="Add1">添加</el-button>
              </div>
            </div>
            <el-row :gutter="15" v-for="(item,index) in form.variable_setting.member_join.data" :key="index">
              <el-col :span="6">
                <el-form-item label-width="0px">
                  <el-select
                    clearable
                    v-model="item.vip_id"
                    placeholder="请选择会员等级"
                    class="ele-fluid">
                    <el-option
                      v-for="(item) in dict.type.vip_grade"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label-width="80px" label="大于：">
                  <el-input placeholder="请输入" v-model="item.min">
                    <template slot="append">小时</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label-width="80px" label="奖励：">
                  <el-input placeholder="请输入" v-model="item.award">
                    <template slot="append">元/单</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-button type="danger" @click="del1(item,index)">删除</el-button>
            </el-row>
          </div>

        </div>

      </el-form>
    </el-card>

    <div style="margin-top: 20px;text-align: center;">
      <el-button
        style="margin-left: 20px;width: 240px;"
        type="primary"
        v-auths="[`${$config.uniquePrefix}operate:promotionSettings:put`]"
        @click="save()">提交
      </el-button>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import {getget_popularize_config, Saveset_popularize_config} from "@/api/operate";

export default {
  data(){
    return{
      form:{},

      //字典
      dict: {
        type:{}
      },

    }
  },

  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '运营字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    //获取配置
    this.getConfig();
  },

  methods:{
    //获取配置
    getConfig(){
      getget_popularize_config().then(res => {
        console.log(res)
        this.form = res.data;
        if(this.form.variable_setting.driver.time_horizon.am.length == 0) {
          this.$set(this.form.variable_setting.driver.time_horizon,'am',["08:00:00", "10:00:00"]);
        }
        if(this.form.variable_setting.driver.time_horizon.noon.length == 0){
          this.$set(this.form.variable_setting.driver.time_horizon,'noon',["12:00:00", "14:00:00"]);
        }
        if(this.form.variable_setting.driver.time_horizon.pm.length == 0){
          this.$set(this.form.variable_setting.driver.time_horizon,'pm',["18:00:00", "20:00:00"]);
        }
      })
    },

    //每日接单奖励添加
    Add(){
      let where = {
        award: "",
        min: ""
      }
      this.form.variable_setting.everyday_join.data.push(where);
    },
    //删除
    del(item,index){
      this.form.variable_setting.everyday_join.data.splice(index,1);
    },

    //会员接单奖励
    Add1(){
      let where = {
        award: "",
        min: "",
        vip_id: ""
      }
      this.form.variable_setting.member_join.data.push(where);
    },
    del1(item,index){
      this.form.variable_setting.member_join.data.splice(index,1);
    },

    //保存
    save(){
      let where = {
        data: this.form
      }
      Saveset_popularize_config(where).then(res => {
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getConfig();
        }else {
          this.$message.error(e.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

  },

}
</script>

<style lang="scss" scoped>
  .tuiguangdiv{
    margin-top: 20px;border: 1px solid rgb(216, 218, 220);padding: 20px;border-radius: 10px;
  }
  .tuiguangdiv1{
    margin-bottom: 10px;display: flex;align-items: center;justify-content: space-between;
  }
  .tuiguangdiv2{
    margin-left: 20px;margin-right: 10px;
  }
</style>
